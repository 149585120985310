// Common styles
@import 'styles-common/_a-logo';
@import 'styles-common/_animations';
@import 'styles-common/_variables';
@import 'styles-common/_labels';
@import 'styles-common/_not-logged-in';

@import 'styles-teaser/_mixins/typography';

// Widgets
@import './../../../src/aftenposten/widgets/Games/styles/games-mobile';
@import 'styles-mobile/widgets/widget-wrapper';
@import './../../../src/aftenposten/widgets/Podcast/component/styles/podcast-teaser';
@import './../../../src/aftenposten/widgets/FollowedContent/styles/followed-content-widget-mobile';
//Stripe
@import '../../core/scss/infeed-show-hide';
// styles for continue as popup
@import '../../core/scss/shame';

.brandstudio-premium-content-widget {
  .premium-spot {
    display: flex;
  }
}
