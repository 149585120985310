.widget-followed-content {
  background-color: var(--c-light);
  padding: var(--space-6) 0;

  .feed {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 49px;
  }

  .feed-item {
    padding: 0px;
    position: relative;
  }

  .feed-item:not(:first-child)::after {
    content: '';
    position: absolute;
    left: -24.5px;
    height: 100%;
    bottom: 0;
    width: 1px;
    border-right: var(--divider-line);
    z-index: 1;
  }

  .teaser-link {
    display: flex;
    flex-direction: column;
    gap: 16px;
    position: relative;
    justify-items: flex-start;

    &:focus {
      outline: auto;
      outline-color: blue;
    }
  }

  .container {
    margin-right: auto;
    padding: 0;
    padding-right: 9px;

    .information {
      margin-bottom: var(--space-2);
      font-weight: 600;
      font-size: 15px;
      color: var(--c-text-link);
    }
  }

  .teaser-image-wrapper {
    width: 100%;
    color: transparent;
    border-radius: 4px;
    aspect-ratio: 1 / 1;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-radius: inherit;
    }
  }

  .author-image-wrapper-follow {
    width: 100%;
    aspect-ratio: 1 / 1;
    border-radius: 4px;

    .author-image {
      width: 100%;
      height: 100%;
      border: none;
      object-fit: cover;
      border-radius: inherit;
    }
  }

  .information {
    font-family: $fontGraphik;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
  }

  .text-container {
    font-family: $fontGraphik;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.3px;
    font-weight: 500;
  }

  .feed-item.opinion .text-container {
    font-style: normal;
  }
}

.followed-content-widget-edit-page-link {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-family: $fontGraphik;
  font-weight: 500;
  line-height: 22px;
  margin: 0;
  color: var(--c-text-link);

  &:visited {
    color: var(--c-text-link);
  }

  &:active,
  &:focus {
    color: var(--c-hyperlink-pressed);
  }

  &:hover {
    cursor: pointer;
    color: var(--c-text-link-hover);
  }

  &::before {
    display: inline-block;
    content: '';
    background-image: url('https://cdn.aftenposten.no/ap/images/active-personalization/edit.svg');
    background-repeat: no-repeat;
    background-size: 16px 16px;
    width: 16px;
    height: 16px;
    margin-right: var(--space-2);
  }
}
