.not-logged-in {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin: 20px auto 40px;
  padding: 20px;
  max-width: $containerWidth;
  background-color: $colorContentBackground;

  .not-logged-in-logo {
    background: transparent;
    box-shadow: none;
    border: none;
    z-index: 2;
    outline: none;
    width: 50px;
    margin: auto;
  }
  .not-logged-in-title {
    max-width: 250px;
    margin: 20px auto 10px;
    line-height: 1.4em;
    font-size: 20px;
    font-family: $fontPublicoHeadlineBold;
  }
  .not-logged-in-body {
    margin: 0 auto 20px;
    max-width: 270px;
    font-family: $fontRobotoLight;
  }
  .not-logged-in-login-button {
    font-family: $fontRobotoBold;
    font-size: 16px;
    border: medium none;
    outline: none;
    cursor: pointer;
    margin: 1rem auto;
    text-align: center;
    text-decoration: none;
    display: block;
    background-color: $colorDarkBlue;
    border-radius: 999px;
    color: #fff;
    padding: 1em 1.5em;
  }
  .not-logged-in-login-button:hover {
    background-color: $colorElectric;
  }
}
