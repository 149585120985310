$c-dark-blue: #00259d;
$c-dark-ocean-blue: #001352e5;
$c-neutral-grey-30: #dbdbdb;

// Common styles used for all variants
.podcast-teaser-widget {
  --c-play-btn: #{$c-dark-blue};
  --c-special-podcast-bg: #{$c-dark-ocean-blue};
  --c-special-text: #{$c-neutral-grey-30};
  background-color: var(--c-light);
  max-width: 600px;
  padding: 0;
  margin: 0 auto;

  &,
  & * {
    box-sizing: border-box;
  }

  button {
    margin: 0;
    padding: 0;
    border: none;
    outline: 0;
    font-weight: inherit;
    font-style: inherit;
    font-size: 100%;
    font-family: inherit;
    vertical-align: baseline;
    text-align: unset;
    color: inherit;
    background-color: unset;
  }

  // --- App banner component ---
  .app-banner {
    display: flex;
    flex-direction: column;
    background-color: #e8f1fa;
    border-radius: 4px;
    font-family: Graphik;
    padding: var(--space-4);
    gap: var(--space-4);

    &__content {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: var(--space-4);
    }

    &__text {
      margin: 0;
      font-size: 15px;
      font-weight: 600;
      line-height: 22px;
      color: var(--c-dark);
    }
  }

  // --- AP logo component ---
  .ap-logo {
    background-color: var(--c-light);
    border-radius: 8px;
    height: fit-content;
    padding: var(--space-2);
    padding-bottom: calc(var(--space-2) - 2px);

    &__logo {
      width: 26px;
      height: 26px;
    }
  }

  // --- App redirect redirect ---
  .app-redirect-link {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--c-light);
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    background-color: var(--c-blue-50);
    border-radius: 100px;
    padding: 12px 16px;
    gap: var(--space-2);

    &:active {
      background-color: var(--c-dark);
    }

    svg {
      width: 14px;
    }
  }

  // --- Divider component ---
  .divider {
    margin: 0;
    background-color: var(--c-primary-04);
    display: block;
    opacity: 10%;
    height: 1px;
  }

  &__image-container {
    margin: 0;
    padding: var(--space-4) var(--space-4) var(--space-3) var(--space-4);
    width: 100vw;
    max-width: 600px;
    overflow: hidden;
  }

  &__image {
    max-width: 100%;
    min-height: calc(100vw - var(--space-4) * 2);
    border-radius: 4px;
  }

  // --- Other player component ---
  .other-player-wrapper {
    display: flex;
    position: relative;
    background-color: transparent;
    letter-spacing: -0.007em;
    flex: 0.5;

    .icon__chevron {
      display: inline-block;
      background-repeat: no-repeat;
      background-position: 0 0;
      opacity: 1;
      background-size: contain;

      width: 18px;
      height: 100%;
      margin: 3px 0 0 7px;
      background-image: url('https://cdn.aftenposten.no/ap/images/functional_icons/chevron_down_thin.svg');

      transition:
        transform ease-out 0.25s,
        margin ease-out 0.1s;
    }

    .other-player-btn {
      display: flex;
      border: 1px solid;
      padding: 12px 16px;
      border-radius: 28px;
      font-size: 14px;
      gap: 8px;
      width: 100%;
      justify-content: center;
      align-items: center;
    }

    .other-player__menu {
      z-index: 10;
      position: absolute;
      left: 0;
      top: 120%;
      min-width: 100%;
      width: fit-content;
      list-style-type: none;
      margin: 0;
      padding: var(--space-3) 0 0;
      color: var(--c-shade);
      background-color: var(--c-light);
      letter-spacing: 0.007em;
      border: 1px solid var(--c-neutral-03);
      border-top: none;

      &-item {
        white-space: nowrap;
        padding: 0 var(--space-3) var(--space-3);
        font-weight: 400;

        &:not(:last-child) {
          margin-bottom: var(--space-3);
          border-bottom: 1px solid var(--c-neutral-03);
        }

        &-link {
          display: flex;
          align-items: center;

          &:active {
            color: var(--c-neutral-08);
          }

          .menu-item-label {
            margin-bottom: 1px;
          }

          .icon {
            width: 22px;
            height: 22px;
            background-size: contain;
            margin: 0 var(--space-3) 0 0;

            &__aftenposten {
              background-image: url('https://cdn.aftenposten.no/ap/images/podcasts/ap_app-hermes_logo.webp');
            }

            &__podme {
              background-image: url('https://cdn.aftenposten.no/ap/images/podcasts/podme_icon.svg');
            }

            &__spotify {
              background-image: url('https://cdn.aftenposten.no/ap/images/podcasts/Spotify_logo.svg');
            }

            &__itunes {
              background-image: url('https://cdn.aftenposten.no/ap/images/podcasts/Apple_Podcast_Icon.png');
            }
          }
        }
      }
    }

    &.open {
      .icon__chevron {
        transform: scaleY(-1);
        margin: 0 0 5px 7px;
      }
    }

    &.closed {
      .other-player__menu {
        display: none !important;
      }
    }
  }
  // --- Play icon ---
  .play-icon {
    display: inline-block;
    width: 14px;
    height: 15px;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 1;
    background-size: contain;

    &.play {
      background-image: url('https://cdn.aftenposten.no/ap/images/functional_icons/audio/play.svg');
      transform: translateX(1px);
    }
    &.pause {
      background-image: url('https://cdn.aftenposten.no/ap/images/functional_icons/audio/pause.svg');
      height: 12px;
    }
  }

  // --- Play btn component ---
  .play-btn {
    margin: 0;
    justify-content: center;
    white-space: nowrap;

    &.web.locked {
      border: 1px solid;
      padding: 12px 16px;
      border-radius: 28px;
    }

    &.hermes.locked {
      font-size: 12px;
      color: var(--c-neutral-07);
      border: 0;
      padding: 0;
      width: fit-content;
      display: inline-block;
    }

    &.small {
      &.playing,
      &.paused {
        border-radius: 100%;
        padding: var(--space-2);
        flex: 0;
        width: 32px;
        height: 32px;
        background-color: var(--c-play-btn);
        border: 1px solid var(--c-play-btn);
      }
    }

    &.large {
      display: flex;
      border: 1px solid;
      padding: 12px 0;
      border-radius: 28px;
      gap: 8px;
      font-size: 14px;
      flex: 0.5;

      & > .play-icon {
        filter: brightness(0) saturate(100%) invert(11%) sepia(54%)
          saturate(6224%) hue-rotate(224deg) brightness(95%) contrast(114%);
      }
    }

    &.filled {
      background-color: var(--c-play-btn);
      color: white;
      & > .play-icon {
        filter: none;
      }
    }
  }

  // --- Duration component ---
  .duration {
    position: relative;
    color: var(--c-neutral-07);
    font-weight: inherit;
  }

  // --- Bottom bar (used for large and list variant) ---
  .bottom-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: var(--space-4);
    font-family: Graphik;
    font-weight: 500;
    font-size: 14px;
    color: var(--c-play-btn);

    @media (max-width: 360px) {
      gap: var(--space-2);
    }

    &__button {
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: center;
      text-align: center;
      white-space: nowrap;
      border-radius: 24px;
      height: 38.5px;
      line-height: 22px;
      box-shadow: 0 0 0 1px var(--c-play-btn);
      border: 1px solid var(--c-play-btn);
      border: 0;
      margin: 0;

      &--play {
        background-color: var(--c-play-btn);
        color: var(--c-light);
      }

      &--other-player {
        color: var(--c-play-btn);
      }

      .icon {
        display: inline-block;
        width: 14px;
        height: 15px;
        background-repeat: no-repeat;
        background-position: 0 0;
        opacity: 1;
        background-size: contain;
        margin-right: 8px;
      }

      &:not(.non-clickable):active,
      &:not(.non-clickable):focus-visible {
        box-shadow: 0 0 0 2px var(--c-play-btn) !important;
      }
    }
  }
}

.podcast-teaser-widget.tts-playlist-teaser {
  .other-player-playlist-wrapper {
    display: flex;
    width: 100%;
  }

  .icon__chevron-right {
    height: 16px;
    width: 16px;
    margin: 2px 0px 0px 8px;
    background-image: url('https://cdn.aftenposten.no/ap/images/functional_icons/chevron_down.svg');
    background-size: contain;
    background-repeat: no-repeat;
    transform: rotate(270deg);
  }
}

.podcast-teaser-widget.large {
  .bottom-bar > * {
    width: 100%;
  }

  .bundle-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: var(--space-4);
    background: var(--c-light);

    .title {
      font-family: $fontGraphik;
      font-size: 16px;
      line-height: 22px;
      color: var(--c-dark);
      font-weight: 500;
      letter-spacing: 0;
      display: flex;
      align-items: center;
    }

    .cta {
      display: flex;
      align-items: center;
      @include label-1($c-dark-blue, 500);

      & .arrow-right {
        fill: var(--c-primary-03);
        margin-left: var(--space-2);
      }
    }

    &::after {
      content: none;
    }
  }

  .app-redirect-link {
    flex: 0.5;
    padding: 12px 0;
  }

  .player-wrapper {
    &:focus-visible {
      outline: -webkit-focus-ring-color auto 1px;
    }

    .information {
      padding: 0 var(--space-4);
      margin-bottom: var(--space-4);

      .podcast-title {
        @include label-2(var(--c-dark), 600);
        color: var(--c-text-link);
        margin-bottom: var(--space-2);
        width: fit-content;
      }

      .episode-title {
        @include title();
      }
    }
  }

  p.player-wrapper {
    margin: 0;
  }

  .container {
    display: flex;
    flex-direction: column;
    gap: var(--space-4);
    padding: 0 var(--space-4);

    .description {
      @include label-1(var(--c-text-ingress-and-body));
      margin: 0;
      line-height: 20px;
      white-space: pre-line;
      word-break: break-word;

      .expandable {
        font-family: Graphik;
        color: inherit;
        font-weight: inherit;
        text-decoration: none;
        text-align: left;
        background: transparent;
        border: none;
        margin: 0;
        padding: 0;
        line-height: inherit;

        &__button {
          width: fit-content;
          white-space: nowrap;
          font-weight: 500;
          display: flex;
          align-items: center;
          color: var(--c-play-btn);
        }

        &:focus-visible {
          outline: -webkit-focus-ring-color auto 1px;
        }

        span {
          color: var(--c-text-link);
          font-weight: 600;
          text-decoration: underline;
          white-space: nowrap;
          margin-left: 6px;
        }
      }
    }
  }
}

.podcast-teaser-widget.list {
  padding: 0 var(--space-4);

  .episode-time {
    font-family: Graphik;
    font-size: 12px;
    line-height: 16px;
    color: var(--c-neutral-07);
    display: flex;
    gap: var(--space-1);
    font-weight: 400;

    @media (max-width: 360px) {
      font-size: 11px;
    }
  }

  .play-btn {
    pointer-events: none;

    &.web.locked {
      flex: 0.8;
    }
  }

  .list-item {
    padding: var(--space-4) 0;

    .player-wrapper {
      display: flex;
      gap: var(--space-4);

      .episode-image-container {
        margin: 0;
        padding: 0;
        width: 142px;
        height: 142px;
        aspect-ratio: 1/1;
        flex-shrink: 0;
      }

      .episode-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: var(--image-radius);
      }

      .bottom-bar.cannot-play {
        @media (max-width: 340px) {
          font-size: 11px;
        }
      }

      .container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 0;
        gap: var(--space-2);

        .information {
          padding: 0;
          margin: 0;
        }

        .information {
          padding: 0;

          .podcast-title {
            @include label-2(var(--c-dark), 600);
            color: var(--c-text-link);
            width: fit-content;
            margin-bottom: var(--space-1);
          }

          .episode-title {
            @include title-podcast();
            line-clamp: 3;
            -webkit-line-clamp: 3;
            overflow: hidden;
            max-height: 70px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
}

// Override widget wrapper footer styles for list variant
.react-widget-wrapper {
  > .podcast-teaser-widget.list
    ~ .react-widget-wrapper__footer
    .react-widget-wrapper__link-arrow {
    background-color: var(--c-light);
    border: 1px solid var(--c-blue-50);

    .arrow-thick {
      filter: brightness(0) saturate(100%) invert(13%) sepia(89%)
        saturate(3725%) hue-rotate(223deg) brightness(81%) contrast(116%);
    }
  }
}

.podcast-teaser-widget.special {
  display: flex;
  margin-bottom: 8px;

  .wrapper {
    margin: var(--space-4);
    background-color: var(--c-special-podcast-bg);
    border-radius: 4px;

    .podcast-title {
      @include label-2(var(--c-special-text), 600);
      margin-bottom: var(--space-1);
      width: fit-content;
    }

    .episode-title {
      @include title();
      color: white;
    }
    .container {
      display: flex;
      @include label-2(var(--c-special-text), 400);
      font-size: 14px;
    }
    .play-btn-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
    }

    .play-btn {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      margin: 16px;

      .circle {
        display: flex;
        border-radius: 50%;
        border: 1px solid var(--c-special-text);
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
      }

      .icon {
        height: 12px;
        background-size: contain;
        background-repeat: no-repeat;
        display: inline-block;
        background-position: center;
        width: 100%;
        filter: brightness(90%);

        &.play {
          background-image: url('https://cdn.aftenposten.no/ap/images/functional_icons/audio/pause.svg');
        }
        &.pause {
          background-image: url('https://cdn.aftenposten.no/ap/images/functional_icons/audio/play.svg');
          transform: translateX(1px);
        }
      }
    }

    .cropped-teaser-image {
      width: 100%;
      aspect-ratio: 3 / 2;
      object-fit: cover;
      object-position: top;
      border-radius: 4px 4px 0px 0px;
    }

    .information {
      margin: 16px 16px 8px 16px;
    }
    .duration {
      color: var(--c-neutral-02);
    }
  }
}

.tts-playlist-teaser {
  .player-wrapper .information {
    padding: 0 var(--space-4) var(--space-3) var(--space-4);
  }
}
