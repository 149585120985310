// AP: don't display section tags for these skins
.fst-News-skin-art-default,
.fst-News-skin-art-grey-line-bottom-2px,
.fst-News-skin-art-grey-line-top-2px,
.fst-News-skin-art-1-negative {
  .fst-News_Label {
    display: none !important;
  }
}

// Hide stripe wrapper for logged in users
header.logged-in ~ .stripe-wrapper {
  display: none;
}

#simplified-login-widget > * {
  z-index: 10;
}

.ab-iam-root.v3 {
  z-index: 100000 !important;
}
