.games-widget {
  display: flex;
  flex-direction: column;
  padding: var(--space-6) var(--space-4);
  gap: var(--space-4);
  margin: 0;

  li {
    list-style: none;
    padding: 0;

    &:not(:last-child) {
      &:after {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        margin: var(--space-4) 0;
        background-color: var(--c-shade-10);
      }
    }
  }

  .game {
    display: flex;
    align-items: end;
    justify-content: space-between;
    flex-wrap: nowrap;
    padding: var(--space-4);
    border-radius: var(--space-1);
    padding: 0 var(--space-2) 0 0;

    &:active {
      background-color: rgba(255, 203, 9, 0.3);
    }

    &:focus {
      outline: auto;
      outline-color: blue;
    }

    .illustration-container {
      width: 80px;
      height: 80px;

      img {
        min-width: 100%;
        height: 100%;
      }
    }

    .title {
      @include title(22px, 28px);
      color: var(--c-dark);
      letter-spacing: 0;
      margin-bottom: var(--space-1);
    }

    .description {
      font-family: $fontGraphik;
      font-size: 14px;
      font-style: normal;
      line-height: 22px;
      font-weight: 400;
      color: var(--c-neutral-09);
      margin: 0 var(--space-2) 0 0;
    }
  }
}
