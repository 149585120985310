.react-widget-wrapper {
  --padding: 16px;

  max-width: 100vw;
  background-color: var(--c-light);
  padding-bottom: var(--space-4);
  margin-bottom: var(--space-2);

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--space-4) 0 var(--space-2);
    margin: 0 var(--space-4);
    border-bottom: var(--divider-line-widget);
  }

  &__header-left {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
  }

  &__icon-container {
    display: flex;
    flex-shrink: 0;
    height: 16px;
    width: 16px;
    margin-right: var(--space-2);
  }

  &__header-icon {
    object-fit: contain;
  }

  &__header-title {
    @include title-widget();
  }

  &__link {
    @include label-1(var(--c-text-link), 500);
    display: flex;
    align-items: center;
    flex-wrap: nowrap;

    &:visited {
      color: var(--c-text-link);
    }

    &:active,
    &:focus {
      color: var(--c-hyperlink-pressed);
    }

    &:hover {
      cursor: pointer;
      color: var(--c-text-link-hover);
    }

    svg {
      margin-left: 6px;
    }

    &-arrow {
      display: flex;
      border-radius: 100%;
      background-color: var(--c-text-link);
      padding: 10px;
      margin-left: auto;

      .arrow-thick {
        width: var(--space-3);
        height: var(--space-3);
        filter: brightness(0) saturate(100%) invert(97%) sepia(100%)
          saturate(0%) hue-rotate(34deg) brightness(107%) contrast(103%);
      }
    }
  }
  &__button {
    background: none;
    width: 100%;
    display: flex;
    justify-content: space-between;
    border: none;
    align-items: center;
    padding: 0;
    @include label-1(var(--c-text-link), 500);

    .read-more-toggle-chevron {
      display: flex;
      border-radius: 100%;
      background-color: var(--c-text-link);
      padding: var(--space-2);
      margin-left: auto;

      .chevron {
        width: var(--space-3);
        height: var(--space-3);
        filter: grayscale(100%) brightness(100);
      }
    }
  }

  &__footer {
    margin: 0 var(--space-4);
    padding: var(--space-2) 0;
    border-top: var(--divider-line);
    border-bottom: var(--divider-line);
  }
}

.oppsummert {
  padding-top: 28px;

  .react-widget-wrapper__header {
    padding-bottom: 8px;
  }

  .react-widget-wrapper__header-title {
    font-family: Publico Headline;
    font-size: 22px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: -0.3px;
    text-align: left;
  }
}
