@import './followed-content-widget.scss';

.widget-followed-content.mobile {
  margin: 0;
  padding: 0 var(--space-4);
  border-bottom: none;

  .header {
    padding: 0;
    margin-bottom: 0;
    border-bottom: 2px solid var(--c-primary-04);
  }

  .feed {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 16px;
    position: relative;
  }

  .feed-item {
    padding: var(--space-4) 0;
    border: none;
    position: relative;
  }
  .feed-item::after {
    display: none;
  }

  .feed-item:nth-child(2n):not(:nth-last-child(-n + 1))::before {
    content: '';
    position: absolute;
    left: calc(-100% - var(--space-4));
    width: calc(200% + var(--space-4));
    bottom: -8px;
    height: 1px;
    border-bottom: var(--divider-line);
    z-index: 1;
  }

  .teaser-link {
    align-items: center;
    gap: 8px;
  }

  .teaser-image-wrapper,
  .author-image-wrapper {
    width: 100%;

    img {
      width: 100%;
      object-fit: cover;
    }
  }
  .author-image-wrapper--no-grayscale,
  .teaser-image-wrapper {
    align-self: baseline;
  }

  .container {
    padding-left: 0;
    padding-right: var(--space-4);
    width: 100%;
  }

  .text-container {
    line-height: 20px;
  }
}
