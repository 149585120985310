.label-basic {
  display: inline-block;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 0.8px;
  color: $colorApDark;
  font-family: $fontRobotoBold;
  font-weight: bold;
  width: 100%;

  &::before {
    content: '';
    display: none;
    width: 15px;
    height: 11px;
    position: relative;
    top: 1px;
    background: url('https://cdn.aftenposten.no/ap/images/functional_icons/quote/quote-blue.svg')
      no-repeat;
    background-size: 15px 11px;
  }

  .article-author-name,
  .paywall-label.closed {
    color: $colorApDark;
    font-family: $fontRobotoLight;
    font-weight: normal;
  }

  .article-author-name {
    line-height: 1.3;
  }
}

article.paywall-closed {
  .article-author-name {
    display: none;
  }
}

.paywall-label {
  display: none;
}

.paywall-label.closed {
  display: inline-block;
}

.skin-dark .label-basic {
  display: inline-block;
  color: #fff;
}

.feature-article-label > .paywall-label.closed {
  color: #adbaca;
}

.presentation-label {
  display: none;
  position: relative;
  color: $colorApDark;

  .video-label,
  .photo-feature-label {
    display: none;
  }

  .presentation-photo-feature &,
  .presentation-election & {
    display: inline-block;
    height: 12px;

    &::before {
      content: '';
      position: absolute;
      display: block;
      width: 20px;
      left: 7px;
    }

    &::after {
      position: absolute;
      font-size: 12px;
      top: 0;
      left: 27px;
    }
  }
}

$config: (
  meninger: (
    markQuote: true,
    tripleColumnGrid: 70px,
  ),
  debatt: (
    markQuote: true,
    tripleColumnGrid: 50px,
  ),
  kommentar: (
    markQuote: true,
    tripleColumnGrid: 85px,
  ),
  sid: (
    markQuote: true,
    tripleColumnGrid: 25px,
  ),
  kronikk: (
    markQuote: true,
  ),
  leder: (
    markQuote: true,
  ),
  nyhetsanalyse: (
    markQuote: true,
  ),
  nyheter: (
    markQuote: true,
  ),
  election: (
    tripleColumnGrid: 25px,
  ),
  kommunevalget: (
    statsIcon: true,
  ),
  e24: (
    e24Icon: true,
  ),
);

@mixin triple-column-label($c1, $c2, $c3) {
  display: grid;
  grid-template-columns: $c1 $c2 $c3;
  align-items: baseline;

  &::before {
    top: 0px;
    position: relative;
  }

  .article-author-name {
    display: flex;

    .hide-on-stack {
      margin-right: 5px;
    }
    & + .article-author-name {
      grid-area: 3 / 3 / 2 / 5;
    }
  }
}

@each $value, $key in $config {
  .section-#{$value} {
    @if map-has-key($key, sectionColor) {
      .label-basic {
        color: map-get($key, sectionColor);
      }
    }

    @if map-has-key($key, markQuote) {
      .label-basic {
        &::before {
          display: inline-block;
        }
      }
    }

    @if map-has-key($key, tripleColumnGrid) {
      &.paywall-metered {
        .label-basic {
          @include triple-column-label(
            20px,
            map-get($key, tripleColumnGrid),
            1fr
          );
        }
      }
    }

    // hides quote icon
    @if map-has-key($key, e24Icon) {
      .label-basic {
        &::before {
          content: '';
        }
      }
    }
  }
}

.section-icon {
  font-size: 0;
  color: transparent;
  height: 12px;

  &::before {
    content: '';
    display: inline-block;
    background-position: bottom;
    background-size: contain;
    background-repeat: no-repeat;
    position: relative;
  }

  &__e24 {
    margin-left: 4px;

    &::before {
      width: 23px;
      height: 13px;
      background-image: url('https://cdn.aftenposten.no/ap/images/content-sharing/E24-Logo-NoBar-Black.svg');
      background-size: contain;
    }
  }
}
.breaking {
  .context-label {
    margin-right: 4px;
  }
  .context-label-wrapper {
    display: flex;
    align-items: center;
    font-family: Graphik;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    text-align: left;
  }
  .context-label-from {
    margin-left: 4px;
  }
  .section-icon__e24 {
    display: flex;
    align-items: end;

    &::before {
      width: 24px;
      height: 9px;
    }
  }
}
.skin-dark,
.inverted,
.large.feature,
.medium.feature {
  .section-icon {
    &::before {
      background-size: contain;
    }

    &__e24::before {
      background-image: url('https://cdn.aftenposten.no/ap/images/content-sharing/E24-Logo-NoBar-Light.svg');
    }
  }
}

.article--small {
  .presentation-label,
  .paywall-label {
    font-size: 10px;
  }
}

.breaking-news {
  .label-basic {
    position: relative;
    padding-left: $gapStandard;

    &::before {
      @include redCircle();
    }

    &::after {
      @include beeper();
    }
  }
}

.section-video,
.photo-feature-label,
.presentation-photo-feature,
.presentation-election {
  .label-basic {
    display: flex;
  }
  .presentation-label {
    width: 22px;
    order: -1;
    &::before {
      left: 0;
    }
    .hide-on-stack {
      display: none;
    }
  }

  .paywall-label.closed {
    margin-left: 5px;
  }
}

// comment counter styles
.has-comments-counter {
  .label-basic {
    width: 100%;
  }

  .label--comments {
    float: right;
    font-weight: normal;

    .widget-comment-counter .counter-container {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;

      .plural {
        order: 1;
      }
    }

    .counter-icon {
      &::after {
        content: '';
        display: block;
        background: url('https://cdn.aftenposten.no/ap/images/functional_icons/comments/comments-dark.svg')
          left center no-repeat;
        width: 20px;
        height: 16px;
      }
    }
  }

  &.article-body-large {
    .article-author-name {
      display: inline-block;
      .hide-on-stack {
        display: initial;
      }
    }
    .label--comments {
      margin-top: 5px;
    }
    &.paywall-closed {
      .article-author-name {
        display: none;
      }
      .label--comments {
        margin-top: 0;
      }
    }
  }

  // handles label names in narrow desktop articles
  &:not(.article-auto-size):not(.article--medium) {
    @each $value, $key in $config {
      &.section-#{$value} {
        .label-basic {
          display: grid;
          grid-template-columns: 20px 85px 1fr;
        }

        &:not(.paywall-closed):not(.commentary-style) {
          .article-author-name {
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }

        .paywall-label.closed,
        .article-author-name {
          line-height: 1.4em;
          grid-column: 1 / -1;
          .hide-on-stack {
            display: none;
          }
        }

        .label--comments {
          grid-row: 1;
          grid-column: 3;
        }
      }
    }

    &.section-sid {
      .label-basic::before {
        position: relative;
      }
    }

    &.paywall-closed:not(.section-nyhetsanalyse) {
      .label-basic {
        display: block;
      }
    }
  }
}

// big article in bundle comment styles
.bundle-block {
  .article-auto-size.has-comments-counter {
    .label-basic {
      display: flex;
      align-items: center;
      .label--comments {
        flex-grow: 1;
      }
    }
  }
}
