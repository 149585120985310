// fallbacks
$fontSerif: Georgia, Times, 'Times New Roman', serif;
$fontSans: Arial, Helvetica, sans-serif;
$fontSansBold: 'Arial-Bold', $fontSans;

$fontGraphik: Graphik, $fontSans;
$fontGraphikSemibold: Graphik-Semibold, $fontSans;
$fontGraphikXCond: GraphikXCond, $fontSansBold;
$fontPublico: Publico, $fontSerif;
$fontPublicoBold: 'Publico-Bold', $fontSerif;
$fontPublicoHeadline:
  Publico Headline,
  $fontSerif;
$fontPublicoHeadlineCond:
  Publico Headline Cond,
  $fontSerif;

$fontProdukt: Produkt, $fontSans;
$fontProduktSemibold: 'Produkt-Semibold', $fontSans;
$fontProduktXXCond: ProduktXXCond, $fontSerif;

@mixin line-clamp($lines: 4) {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $lines;
  text-overflow: ellipsis;
  overflow: hidden;
}

@mixin label-1($color: var(--c-dark), $weight: normal) {
  font-family: $fontGraphik;
  font-size: 14px;
  line-height: 16px;
  color: $color;
  font-weight: $weight;
  letter-spacing: 0;
}

@mixin label-2($color: var(--c-dark), $weight: normal) {
  font-family: $fontGraphik;
  font-size: 12px;
  line-height: 16px;
  color: $color;
  font-weight: $weight;
  letter-spacing: 0;
}

@mixin label-3($color: var(--c-dark), $weight: normal, $style: normal) {
  font-family: $fontPublico;
  font-size: 12px;
  font-style: $style;
  font-weight: $weight;
  line-height: 16px;
  color: $color;
}

@mixin title($fs: 26px, $lh: 30px, $style: normal, $weight: 500) {
  font-family: $fontPublicoHeadline;
  font-size: $fs;
  font-style: $style;
  font-weight: $weight;
  line-height: $lh;
  letter-spacing: -0.3px;
}

@mixin title-paragraph(
  $color: var(--c-neutral-09),
  $fs: 19px,
  $lh: 23px,
  $style: normal,
  $weight: 600
) {
  font-family: $fontGraphik;
  font-size: $fs;
  font-style: $style;
  font-weight: $weight;
  line-height: $lh;
  letter-spacing: -0.5px;
  color: $color;
}

@mixin title-breaking($fs: 28px, $lh: 30px, $ls: -0.5px) {
  font-family: $fontGraphik;
  font-size: $fs;
  font-style: normal;
  font-weight: bold;
  line-height: $lh;
  letter-spacing: $ls;
}

@mixin title-magazine($fs: 36px, $lh: 40px) {
  font-family: $fontPublicoHeadlineCond;
  font-size: $fs;
  font-style: normal;
  font-weight: 500;
  line-height: $lh;
  letter-spacing: 0px;
}

@mixin title-history-magazine($fs: 32px, $lh: 32px) {
  font-family: $fontPublicoHeadline;
  font-size: $fs;
  line-height: $lh;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.3px;
}

@mixin title-feature($fs: 17px, $lh: 24px, $fw: 700) {
  font-family: $fontProdukt;
  font-size: $fs;
  font-style: normal;
  font-weight: $fw;
  line-height: $lh;
}

@mixin title-oslo($fs: 15px, $lh: 19px) {
  font-family: $fontGraphik;
  font-size: $fs;
  line-height: $lh;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.2px;
}

@mixin description($fs: 14px, $lh: 18px, $color: var(--c-neutral-06)) {
  font-family: $fontGraphik;
  font-style: normal;
  font-weight: normal;
  font-size: $fs;
  line-height: $lh;
  color: $color;
}

@mixin title-live($fs: 15px, $lh: 19px, $weight: 600) {
  font-family: $fontGraphik;
  font-weight: $weight;
  font-size: $fs;
  line-height: $lh;
}

@mixin title-list() {
  font-family: $fontProdukt;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 24px;
  color: var(--c-dark);
}
@mixin title-widget() {
  font-family: $fontProdukt;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 24px;
  color: var(--c-dark);
}

@mixin title-subpage() {
  font-family: $fontPublicoHeadlineBold;
  font-size: 32px;
  font-weight: 500;
  line-height: 35px;
  letter-spacing: -0.5px;
  color: var(--c-dark);
}

@mixin title-podcast() {
  font-family: $fontGraphik;
  font-size: 18px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 0px;
  color: var(--c-dark);
}

@mixin title-list-item() {
  font-family: $fontGraphik;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 19px;
  letter-spacing: -0.3px;
}

@mixin index() {
  font-family: $fontProdukt;
  font-weight: 300;
  font-size: 28px;
  line-height: 30px;
  letter-spacing: -0.5px;
}

@mixin bundle-summary($ff: $fontPublico, $fw: 400) {
  font-family: $ff;
  font-size: 15px;
  line-height: 21px;
  font-weight: $fw;
  color: var(--c-neutral-09);
}

@mixin summary-block($margin-right, $baseWidth) {
  @include bundle-summary();
  width: calc(#{$baseWidth} + var(--space-4));
  flex-shrink: 0;
  margin-right: $margin-right;
  padding-left: var(--space-6);
  border-left: var(--divider-line);

  p,
  ul,
  ol {
    margin-bottom: var(--space-2);
  }

  p {
    @include line-clamp(6);
  }

  ol,
  ul {
    list-style: revert;
  }

  h1 {
    @include bundle-summary($fontPublicoBold);
    margin: 0;
  }

  strong {
    font-family: $fontPublicoBold;
  }
}

@mixin font-bold($ff-bold) {
  strong,
  b,
  h1 {
    font-family: $ff-bold;
    font-weight: bold;
  }
}

@mixin expandable-summary(
  $fs: 14px,
  $lh: 20px,
  $ff: $fontGraphik,
  $ff-bold: $fontGraphik
) {
  font-size: $fs;
  line-height: $lh;
  font-family: $ff;
  margin: 0 var(--space-4);
  padding: var(--space-4) 0;
  color: var(--c-neutral-09);
  border-bottom: var(--divider-line);
  display: flex;
  flex-direction: column;

  & h1 {
    font-size: 14px;
    line-height: 16px;
  }

  & p {
    margin: 0;
  }

  & ul {
    list-style-type: none;
    margin: 0;
    padding-left: 0;

    & li + li {
      margin-top: var(--space-4);
    }

    & li {
      list-style-type: none;
    }

    & li::before {
      content: '';
      display: inline-block;
      width: 6px;
      height: 6px;
      background-color: var(--c-neutral-09);
      border-radius: 50%;
      vertical-align: 2px;
      margin-right: var(--space-1);
    }

    & p {
      display: inline;
    }
  }

  & summary {
    list-style: none;

    &::-webkit-details-marker {
      display: none;
    }

    & li::after,
    & p::after {
      content: 'Se mer';
      font-weight: bold;
      font-family: $fontGraphik;
      color: var(--c-primary-03);
    }

    & li p::after {
      content: none;
    }

    @include font-bold($ff-bold);
  }

  &[open] {
    -webkit-line-clamp: initial;

    & summary {
      display: none;
    }

    @include font-bold($ff-bold);
  }
}
