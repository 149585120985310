@use "sass:math";

@keyframes pulse-breaking {
  0% {
    transform: scale(0.4);
    opacity: 0.4;
  }

  80%,
  100% {
    opacity: 0;
  }
}

@mixin redCircle($top: 0, $left: 0, $color: $colorOrange, $size: $gridBase) {
  content: '';
  display: inline-block;
  border-radius: 100%;
  width: $size;
  height: $size;
  border: 1px solid $color;
  background: $color;
  position: absolute;
  top: $top;
  left: $left;
}

@mixin beeper($top: -$gridBase, $left: -$gridBase, $iteration-count: 5) {
  @include redCircle($top, $left);
  width: $gapMedium;
  height: $gapMedium;
  top: $top;
  left: $left;
  animation: pulse-breaking 2s cubic-bezier(0.165, 0.84, 0.44, 1);
  animation-iteration-count: $iteration-count;
  animation-fill-mode: forwards;
}

@mixin spin($spinType, $start, $end) {
  @keyframes #{$spinType} {
    from {
      transform: rotate(#{$start});
    }

    to {
      transform: rotate(#{$end});
    }
  }
}

@keyframes slideInFromRight {
  from {
    opacity: 0;
    transform: translateX(14px);
    transform: translate3d(14px, 0, 0);
  }

  to {
    opacity: 1;
    transform: translateY(0);
    transform: translate3d(0, 0, 0);
  }
}

@mixin fadeSlider($imgCount: 1, $fadeLength: 1, $visibleTime: 2) {
  $a: math.div(100, ($fadeLength + $visibleTime) * $imgCount);

  @keyframes fade {
    0% {
      opacity: 0;
    }

    #{$a * 1% * $fadeLength} {
      opacity: 1;
    }

    #{$a * 1% * ($fadeLength + $visibleTime)} {
      opacity: 1;
    }

    #{$a * 1% * ($fadeLength + $visibleTime + $fadeLength)} {
      opacity: 0;
    }

    100% {
      opacity: 0;
    }
  }

  animation-name: fade;
  animation-duration: #{(($fadeLength + $visibleTime) * $imgCount)}s;
  animation-iteration-count: infinite;
  animation-fill-mode: backwards;

  @for $i from 1 through $imgCount {
    &:nth-child(#{$i}) {
      animation-delay: #{($fadeLength + $visibleTime) * ($i - 1)}s;
    }
  }
}
